import React from 'react';
import AuthForm from '../components/AuthForm';

const LoginPage = () => {
  const switchAuthModeHandler = () => {
    // Logic to switch to the signup page
  };

  return <AuthForm isSignUp={false} switchAuthModeHandler={switchAuthModeHandler} />;
};

export default LoginPage;
