import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { apiClient } from "../api/client";
import { useAuth } from "../context/AuthContext";

const CompletionSummaryPage = () => {
  const [startDate, setStartDate] = useState(
    () => localStorage.getItem("startDate") || "",
  );
  const [endDate, setEndDate] = useState(
    () => localStorage.getItem("endDate") || "",
  );
  const [summary, setSummary] = useState([]);
  const [providers, setProviders] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    localStorage.setItem("startDate", startDate);
  }, [startDate]);

  useEffect(() => {
    localStorage.setItem("endDate", endDate);
  }, [endDate]);

  const fetchCompletionSummary = useCallback(
    async (start_date, end_date, user_email) => {
      try {
        const response = await apiClient.get("/completion-summary", {
          params: {
            start_date,
            end_date,
            user_email: user_email || user.email,
          },
        });
        setSummary(response.data.completion_summary || []);
        const uniqueProviders = Array.from(
          new Set(
            response.data.completion_summary.flatMap((item) =>
              Object.keys(item)
                .filter((key) => key.includes("_cost"))
                .map((key) => key.split("_")[0]),
            ),
          ),
        );
        setProviders(uniqueProviders);
      } catch (error) {
        console.error("Failed to fetch completion summary:", error);
      }
    },
    [user.email],
  );

  useEffect(() => {
    if (startDate && endDate) {
      fetchCompletionSummary(startDate, endDate, user.email);
    }
  }, [startDate, endDate, user.email, fetchCompletionSummary]);

  const renderBarChart = (provider) => {
    const providerData = summary
      .map((entry) => ({
        date: entry.date,
        prompt_cost: entry[`${provider}_prompt_cost`],
        completion_cost: entry[`${provider}_completion_cost`],
      }))
      .filter(
        (entry) =>
          entry.prompt_cost !== undefined ||
          entry.completion_cost !== undefined,
      );

    return (
      <Box key={provider} sx={{ mb: 4 }}>
        <Typography variant="h6">{provider} Costs</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={providerData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip cursor={false} />
            <Legend />
            <Bar
              dataKey="prompt_cost"
              stackId="a"
              fill="#8884d8"
              name="Prompt Cost"
            />
            <Bar
              dataKey="completion_cost"
              stackId="a"
              fill="#82ca9d"
              name="Completion Cost"
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
        />
        <Button
          variant="contained"
          onClick={() => fetchCompletionSummary(startDate, endDate, user.email)}
        >
          Fetch Summary
        </Button>
      </Box>
      {providers.map((provider) => renderBarChart(provider))}
    </Box>
  );
};

export default CompletionSummaryPage;
