import React, { useState, useEffect } from "react";
import ReactGA4 from "react-ga4";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SubscriptionModal from "./SubscriptionModal";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated, handleLogout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  // Track the scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogoutClick = () => {
    handleClose(); // Make sure to close the menu
    handleLogout();
  };

  const handleSubscriptionOpen = () => {
    // Google Analytics 4
    ReactGA4.send("event", {
      name: "open_subscription",
      action: "click",
      params: {
        user_id: user.user_id,
        email: user.email,
      },
    });
    handleClose();
    setModalOpen(true);
  };

  const [value, setValue] = useState(location.pathname);
  useEffect(() => {
    setValue(
      location.pathname.includes("/dashboard")
        ? "/dashboard"
        : location.pathname,
    );
  }, [location]);

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  // Determine AppBar background and text colors based on theme mode and scroll position
  const appBarStyle = {
    transition: "all 0.3s ease",
    backgroundColor: isScrolled
      ? theme.palette.mode === "dark"
        ? theme.palette.background.paper
        : "rgba(255,255,255,0.9)"
      : "transparent",
    color: isScrolled
      ? theme.palette.mode === "dark"
        ? theme.palette.text.primary
        : theme.palette.text.primary
      : theme.palette.text.primary,
    boxShadow: "none",
  };

  return (
    <>
      <AppBar position="sticky" style={appBarStyle}>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="Navigation Tabs"
          >
            <Tab label="Home" value="/" />
            {isAuthenticated && user && <Tab label="Docs" value="/docs" />}
            {/* {isAuthenticated && user ? ( */}
            {/*   <Tab label="Dashboard" value="/dashboard" /> */}
            {/* ) : ( */}
            {/*   <Tab label="Login" value="/login" /> */}
            {/* )} */}
          </Tabs>

          {isAuthenticated && user && (
            <IconButton onClick={handleMenu} color="inherit" sx={{ ml: 2 }}>
              <Avatar alt={user.first_name} src={user.picture_url} />
            </IconButton>
          )}
        </Box>
      </AppBar>
      {open && (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
          {user?.user_id && (
            <MenuItem onClick={handleSubscriptionOpen}>Subscription</MenuItem>
          )}
          {user?.user_id && (
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          )}
        </Menu>
      )}
      <SubscriptionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default NavBar;
