import { apiClient } from "./client";

export const loginWithGoogle = () => {
  window.location.href = `${apiClient.defaults.baseURL}/login/google`;
};

export const loginWithGithub = () => {
  window.location.href = `${apiClient.defaults.baseURL}/login/github`;
};

export const loginWithMicrosoft = () => {
  window.location.href = `${apiClient.defaults.baseURL}/login/microsoft`;
};

export const loginWithFacebook = () => {
  window.location.href = `${apiClient.defaults.baseURL}/login/facebook`;
};

export const loginWithApple = () => {
  window.location.href = `${apiClient.defaults.baseURL}/login/apple`;
};

export const logout = async () => {
  await apiClient.post("/logout");
};

export const verifySession = async () => {
  try {
    const response = await apiClient.get("/verify_session");
    return response.data.user;
  } catch (error) {
    throw new Error("Session is not valid");
  }
};

export const processOAuthCallback = async (provider) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code"); // Common for OAuth2 authorization code flow
    const state = urlParams.get("state"); // You should validate this if you sent it during the auth request
    const scope = urlParams.get("scope"); // You should validate this if you sent it during the auth request

    const response = await apiClient.get(`/auth/${provider}`, {
      code,
      state,
      scope,
    });
    if (response.data && response.data.accessToken) {
      // Save the token in local storage
      // localStorage.setItem('accessToken', response.data.accessToken);
      return true;
    }
    throw new Error("Authentication failed: No access token returned");
  } catch (error) {
    throw new Error("Authentication failed: Unable to process OAuth callback");
  }
};
