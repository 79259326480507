import {
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
} from "@mui/material";
import CustomSwitch from "../components/CustomSwitch";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { Snackbar, Alert } from "@mui/material";
import { apiClient } from "../api/client";
import { debounce } from "lodash";

import React, { useState, useEffect, useCallback } from "react";

const LLMProvidersPage = () => {
  const [providers, setProviders] = useState([]);
  const [supportedProviders, setSupportedProviders] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // can be 'error', 'warning', 'info', or 'success'

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleProviderChange = (index, event) => {
    const { name, value, checked } = event.target;
    const updatedProviders = providers.map((provider, idx) =>
      idx === index
        ? { ...provider, [name]: name === "enabled" ? checked : value }
        : provider,
    );
    setProviders(updatedProviders);
    // Check if the updated provider has both a name and an api_token that are not empty
    // Adjust the logic if your structure uses different field names or conditions
    const updatedProvider = updatedProviders[index];
    if (updatedProvider.name && updatedProvider.api_token) {
      setIsDirty(true); // Only set isDirty to true if conditions are met
    }
  };

  const saveProviders = useCallback(async () => {
    if (isDirty) {
      try {
        // Map through providers and exclude the isDirty property from each
        await apiClient.post("/llm-providers", { providers });
        // Reset isDirty flag for all providers after successful save
        setIsDirty(false);
        showSnackbar("Providers saved successfully", "success");
        // Update UI accordingly
      } catch (error) {
        showSnackbar(
          "Failed to save providers. Please try again later.",
          "error",
        );
      }
    }
  }, [isDirty, providers]);

  useEffect(() => {
    const fetchSupportedProviders = async () => {
      try {
        const response = await apiClient.get("/supported-providers");
        setSupportedProviders(response.data.providers);
      } catch (error) {
        console.error("Failed to fetch supported providers:", error);
        showSnackbar("Failed to load supported providers.", "error");
      }
    };

    fetchSupportedProviders();
  }, []);

  useEffect(() => {
    const handleAutoSave = debounce(() => {
      if (isDirty) {
        saveProviders();
      }
    }, 1000); // Adjust debounce timing as needed

    handleAutoSave();

    return () => handleAutoSave.cancel(); // Cleanup on component unmount
  }, [isDirty, providers, saveProviders]); // Depend on providers if the auto-save should react to changes immediately

  // New useEffect for fetching providers
  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await apiClient.get("/llm-providers");
        // Set providers state with the fetched data. Validate the structure of the response data
        const providersData = Array.isArray(response.data.providers)
          ? response.data.providers
          : [];
        setProviders(providersData);
      } catch (error) {
        showSnackbar(
          "Failed to load providers. Please try again later.",
          "error",
        );
        setProviders([]); // Clear providers if the fetch fails
      }
    };

    fetchProviders();
  }, []); // Empty dependency array means this runs once on mount

  const addNewProvider = () => {
    setProviders([
      ...providers,
      {
        name: "",
        api_token: "",
        enabled: true,
      },
    ]);
  };

  const openDeleteDialog = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      setProviders((currentProviders) =>
        currentProviders.filter((_, i) => i !== deleteIndex),
      );
      setIsDirty(true); // Indicate that there are unsaved changes
    }
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        mt: 1,
        flexDirection: "column",
      }}
    >
      {" "}
      {providers.map((provider, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel>Provider Name</InputLabel>
                <Select
                  label="Provider Name"
                  value={provider.name}
                  name="name"
                  onChange={(e) => handleProviderChange(index, e)}
                >
                  {supportedProviders.map((sp, idx) => (
                    <MenuItem key={idx} value={sp.value}>
                      {sp.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="API Token"
                variant="outlined"
                name="api_token"
                value={provider.api_token}
                onChange={(e) => handleProviderChange(index, e)}
                InputProps={{
                  sx: {
                    filter: "blur(4px)",
                    "&:hover": {
                      filter: "none",
                    },
                    "&.Mui-focused": {
                      filter: "none",
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <CustomSwitch
                checked={provider.enabled}
                onChange={(e) => handleProviderChange(index, e)}
                name="enabled"
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => openDeleteDialog(index)} color="error">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addNewProvider}
        sx={{
          mt: 8,
          selfAlign: "flex-start",
        }}
      >
        Add{" "}
      </Button>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this provider? This action cannot be undone."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LLMProvidersPage;
