import React from "react";
import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Outlet, Link, useLocation } from "react-router-dom";

const drawerWidth = 240;
const navbarHeight = 64; // Assuming a fixed navbar height of 64px

const DashboardPage = () => {
  const location = useLocation();
  const theme = useTheme();

  const isActiveRoute = (pathname) => location.pathname.includes(pathname);

  const getTabStyles = (isActive) => ({
    bgcolor: isActive ? theme.palette.action.selected : "transparent",
    color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
    "&:hover": {
      bgcolor: theme.palette.action.hover,
    },
  });

  return (
    <Box sx={{ display: "flex" }}>
      {/* Assuming the top navbar is here with a height of 64px */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              top: navbarHeight, // Start the drawer below the navbar
              height: `calc(100% - ${navbarHeight}px)`, // Adjust the drawer height to fill the rest of the vertical space
            },
          }}
          open
        >
          <List>
            <ListItem
              button
              component={Link}
              to="/dashboard/completion-summary"
              sx={getTabStyles(isActiveRoute("/dashboard/completion-summary"))}
            >
              <ListItemText primary="Summary" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/dashboard/llm-providers"
              sx={getTabStyles(isActiveRoute("/dashboard/llm-providers"))}
            >
              <ListItemText primary="LLM Providers" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/dashboard/access-tokens"
              sx={getTabStyles(isActiveRoute("/dashboard/access-tokens"))}
            >
              <ListItemText primary="Access Tokens" />
            </ListItem>
            {/* Additional ListItems */}
          </List>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardPage;
