// src/components/CustomSwitch.jsx

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        padding: 7,
        '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                // Change to light green when enabled
                backgroundColor: '#81c784', // Example of light green
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        // Default track color when disabled
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default CustomSwitch;
