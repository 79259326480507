import React from "react";
import ReactGA4 from "react-ga4";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useCustomTheme } from "./theme";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ApiDocsPage from "./pages/ApiDocsPage";
import LLMProvidersPage from "./pages/LLMProvidersPage";
import AccessTokensPage from "./pages/AccessTokensPage";
import CompletionSummaryPage from "./pages/CompletionSummaryPage";
import useTrackPageView from "./hooks/useTrackPageView";
import NavBar from "./components/NavBar";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const theme = useCustomTheme();

  // Google Analytics 4
  ReactGA4.initialize("G-CF5QJC7DCE");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <React.StrictMode>
        <BrowserRouter>
          <PageViewTracker /> {/* Component to track page views */}
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <NavBar />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/docs" element={<ApiDocsPage />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<DashboardPage />}>
                    <Route
                      index
                      element={
                        <Navigate replace to="/dashboard/completion-summary" />
                      }
                    />
                    <Route
                      path="completion-summary"
                      element={<CompletionSummaryPage />}
                    />
                    <Route
                      path="llm-providers"
                      element={<LLMProvidersPage />}
                    />
                    <Route
                      path="access-tokens"
                      element={<AccessTokensPage />}
                    />
                  </Route>
                  <Route
                    path="/manage-subscription"
                    element={<HomePage />}
                  ></Route>
                </Route>
              </Routes>
            </Elements>
          </AuthProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  );
}

const PageViewTracker = () => {
  useTrackPageView(); // This ensures useLocation is used in the context of <Router>
  return null; // This component does not render anything
};

export default App;
