import React, { useEffect, useRef } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";

const SubscriptionModal = ({ isOpen, onClose }) => {
  // Ref for the Stripe Pricing Table
  const stripeTableRef = useRef(null);
  const { user } = useAuth();

  useEffect(() => {
    // Dynamically load the Stripe Pricing Table script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  // check is user.user_id is not null. If it is, ask the user to login
  if (!user?.user_id) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px", // Rounded corners
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Please login to view subscription plans
          </Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "16px", // Rounded corners
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Choose Your Plan
        </Typography>
        <div ref={stripeTableRef}>
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={user.user_id}
            customer-email={user.email}
          ></stripe-pricing-table>
        </div>
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;
