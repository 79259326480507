import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { Snackbar, Alert } from "@mui/material";
import { apiClient } from "../api/client";
import DeleteIcon from "@mui/icons-material/Delete";

const AccessTokensPage = () => {
  const [tokens, setTokens] = useState([]);
  const [copiedTokens, setCopiedTokens] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const theme = useTheme();

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await apiClient.get("/access-tokens");
        const tokensData = Array.isArray(response.data.access_tokens)
          ? response.data.access_tokens.map((token) => ({
              ...token,
              isCopied: false, // Add this flag
            }))
          : [];
        setTokens(tokensData); // Adjust according to your API response structure
      } catch (error) {
        showSnackbar("Failed to load tokens. Please try again later.", "error");
        setTokens([]);
      }
    };
    fetchTokens();
  }, []);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const addNewToken = async () => {
    try {
      const response = await apiClient.post("/access-tokens");
      showSnackbar("Token added successfully.", "success");
      const tokensData = Array.isArray(response.data.access_tokens)
        ? response.data.access_tokens
        : [];
      setTokens(tokensData); // Adjust according to your API response structure
    } catch (error) {
      const message =
        error.response?.data?.detail ||
        "Failed to add new token. Please try again later.";
      showSnackbar(message, "error");
    }
  };

  const copyToClipboard = async (text, index) => {
    try {
      await navigator.clipboard.writeText(text);
      showSnackbar("Token copied to clipboard", "success");

      // Set the token as copied
      setCopiedTokens((prev) => ({ ...prev, [index]: true }));

      // Reset the copied status after 2 seconds
      setTimeout(() => {
        setCopiedTokens((prev) => ({ ...prev, [index]: false }));
      }, 3000);
    } catch (err) {
      showSnackbar("Failed to copy token to clipboard", "error");
    }
  };

  const openDeleteDialog = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteIndex !== null) {
      const tokenToDelete = tokens[deleteIndex];
      // get the first 10 characters of the token and use it to identify the token
      const tokenIdentifier = tokenToDelete.access_token.slice(0, 10);
      try {
        await apiClient.delete(`/access-tokens/${tokenIdentifier}`);
        showSnackbar("Token deleted successfully.", "success");
      } catch (error) {
        const message =
          error.response?.data?.detail ||
          "Failed to delete token. Please try again later.";
        showSnackbar(message, "error");
      }
      setTokens((currentTokens) =>
        currentTokens.filter((_, i) => i !== deleteIndex),
      );
    }
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        mt: 1,
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        {/* Headers */}
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle1" component="div">
            <strong>Access Token</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" component="div">
            <strong>Expires</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ textAlign: "right" }}
          ></Typography>{" "}
          {/* Empty header for the actions column */}
        </Grid>

        {/* Mapping through tokens to display each token, its expiration, and the copy button */}
        {tokens.map((token, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{ wordBreak: "break-all", overflowX: "auto" }}
            >
              <Paper
                variant="outlined"
                sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.paper
                      : "#f5f5f5",
                  "&:hover": {
                    ".tokenText": {
                      filter: "none",
                    },
                  },
                }}
              >
                <Typography
                  variant="body2"
                  component="span"
                  className="tokenText"
                  sx={{
                    flexGrow: 1,
                    filter: "blur(4px)",
                    userSelect: "none",
                    "&:hover": {
                      filter: "none",
                      userSelect: "auto",
                    },
                  }}
                >
                  {token.access_token}
                </Typography>
                <Tooltip title="Copy token">
                  <IconButton
                    onClick={() => copyToClipboard(token.access_token, index)}
                    size="small"
                  >
                    {copiedTokens[index] ? (
                      <CheckIcon fontSize="small" />
                    ) : (
                      <ContentCopyIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">{token.expires_at}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => openDeleteDialog(index)} color="error">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addNewToken}
        sx={{
          mt: 8,
          selfAlign: "flex-start",
        }}
      >
        Add{" "}
      </Button>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this token? This action cannot be undone."
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AccessTokensPage;
