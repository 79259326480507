import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import Footer from "./Footer";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const HomePage = () => {
  const sampleChartData = [
    { date: "2024-01-01", prompt_cost: 0.2, completion_cost: 0.4 },
    { date: "2024-01-02", prompt_cost: 0.3, completion_cost: 0.6 },
    { date: "2024-01-03", prompt_cost: 0.5, completion_cost: 0.7 },
    { date: "2024-01-04", prompt_cost: 0.6, completion_cost: 0.9 },
    { date: "2024-01-05", prompt_cost: 0.1, completion_cost: 0.3 },
    { date: "2024-01-06", prompt_cost: 0.4, completion_cost: 0.5 },
    { date: "2024-01-07", prompt_cost: 0.7, completion_cost: 0.8 },
    { date: "2024-01-08", prompt_cost: 0.6, completion_cost: 0.7 },
    { date: "2024-01-09", prompt_cost: 0.2, completion_cost: 0.3 },
    { date: "2024-01-10", prompt_cost: 0.5, completion_cost: 0.5 },
    { date: "2024-01-11", prompt_cost: 0.9, completion_cost: 1.0 },
    { date: "2024-01-12", prompt_cost: 0.8, completion_cost: 0.9 },
    { date: "2024-01-13", prompt_cost: 0.3, completion_cost: 0.4 },
    { date: "2024-01-14", prompt_cost: 0.4, completion_cost: 0.6 },
    { date: "2024-01-15", prompt_cost: 0.7, completion_cost: 0.9 },
    { date: "2024-01-16", prompt_cost: 0.5, completion_cost: 0.6 },
    { date: "2024-01-17", prompt_cost: 0.8, completion_cost: 1.2 },
    { date: "2024-01-18", prompt_cost: 0.2, completion_cost: 0.2 },
    { date: "2024-01-19", prompt_cost: 0.6, completion_cost: 0.8 },
    { date: "2024-01-20", prompt_cost: 0.9, completion_cost: 1.1 },
    { date: "2024-01-21", prompt_cost: 0.4, completion_cost: 0.6 },
    { date: "2024-01-22", prompt_cost: 0.7, completion_cost: 0.7 },
    { date: "2024-01-23", prompt_cost: 0.3, completion_cost: 0.5 },
    { date: "2024-01-24", prompt_cost: 0.5, completion_cost: 0.8 },
    { date: "2024-01-25", prompt_cost: 0.6, completion_cost: 0.7 },
    { date: "2024-01-26", prompt_cost: 0.2, completion_cost: 0.3 },
    { date: "2024-01-27", prompt_cost: 0.5, completion_cost: 0.6 },
    { date: "2024-01-28", prompt_cost: 0.9, completion_cost: 1.0 },
    { date: "2024-01-29", prompt_cost: 0.3, completion_cost: 0.4 },
    { date: "2024-01-30", prompt_cost: 0.4, completion_cost: 0.5 },
    { date: "2024-01-31", prompt_cost: 0.7, completion_cost: 0.9 },
  ];

  const renderBarChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={sampleChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip cursor={false} />
        <Legend align="right" verticalAlign="top" height={36} />
        <Bar
          dataKey="prompt_cost"
          fill="#8884d8"
          name="Prompt Cost"
          stackId="a"
        />
        <Bar
          dataKey="completion_cost"
          fill="#82ca9d"
          name="Completion Cost"
          stackId="a"
        />
      </BarChart>
    </ResponsiveContainer>
  );
  return (
    <>
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box my={4} textAlign="center">
          <Typography
            variant="h2"
            gutterBottom
            style={{ textTransform: "uppercase" }}
          >
            Elevate ambitions <br />
            transcend limits
          </Typography>
          <Typography variant="h4" gutterBottom>
            Revolutionize Your Development with LLM-X.AI
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Seamlessly integrate leading large language models into your
            workflow with a single, secure API.
          </Typography>
        </Box>

        {/* Features Section */}
        <Grid container spacing={4} my={4}>
          {[
            {
              title: "Secure Token Management",
              description:
                "Safeguard your API tokens even in collaborative environments. Our platform ensures that leaving team members don’t affect your access or security.",
            },
            {
              title: "Unified LLM Integration",
              description:
                "Simplify your development process with a single API that connects you to multiple large language model providers, making it easy to switch and select the best fit for your needs.",
            },
            {
              title: "Zero Infrastructure Hassle",
              description:
                "Forget the complexities of setting up and managing infrastructure. Our fully managed and secure platform takes care of it all, letting you focus on what you do best.",
            },
          ].map(({ title, description }) => (
            <Grid item xs={12} sm={4} key={title}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {title}
                  </Typography>
                  <Typography color="textSecondary">{description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box my={4} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Visualize API Usage
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Check out a live demonstration of cost tracking over time.
          </Typography>
          {renderBarChart()}
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom textAlign="center">
            Our Supported Providers
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            gutterBottom
            textAlign="center"
          >
            A growing list of the world's leading large language models.
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {[
              "OpenAI",
              "Anthropic",
              "Hugging Face",
              "Vertex AI",
              "Bedrock",
              "Perplexity",
            ].map((provider) => (
              <Grid item xs={6} sm={4} md={2} key={provider}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" gutterBottom textAlign="center">
                      {provider}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Typography
            variant="body1"
            color="textSecondary"
            mt={2}
            textAlign="center"
          >
            And more to come...
          </Typography>
        </Box>

        {/* Call to Action Section */}
        {/* <Box my={4} textAlign="center"> */}
        {/*   <Typography variant="h4" gutterBottom> */}
        {/*     Ready for Seamless Integration? */}
        {/*   </Typography> */}
        {/*   <Typography variant="subtitle1" color="textSecondary" gutterBottom> */}
        {/*     Join us now and redefine how you build with large language models. */}
        {/*   </Typography> */}
        {/*   <Button */}
        {/*     component={Link} */}
        {/*     to="/docs" */}
        {/*     variant="contained" */}
        {/*     color="secondary" */}
        {/*     size="large" */}
        {/*   > */}
        {/*     Discover the API */}
        {/*   </Button> */}
        {/* </Box> */}
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
