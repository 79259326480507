import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // Send page_view event to GA4
    ReactGA4.initialize("G-CF5QJC7DCE");
    ReactGA4.send("page_view", {
      page_location: window.location.href,
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]); // This effect runs when the location.pathname changes
};

export default useTrackPageView;
