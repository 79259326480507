import React from "react";
import {
  Container,
  Typography,
  // TextField,
  Button,
  Divider,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import AppleIcon from "@mui/icons-material/Apple";
import {
  loginWithGoogle,
  loginWithMicrosoft,
  loginWithApple,
} from "../api/authService";

const AuthForm = ({ isSignUp, switchAuthModeHandler }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  // Define your maxWidth based on your requirements or screen sizes
  // For example, on small screens, you want 100% width, on medium 80%, large 50%, etc.
  const getMaxWidth = () => {
    if (isXs) {
      return "100%"; // On extra small screens, the form takes full width
    }
    return "450px"; // On larger screens, the form width is limited to 450px
  };
  return (
    <Container component="main" maxWidth="xs">
      <Grid
        container
        style={{ height: "100vh" }}
        alignItems="center"
        justify="center"
      >
        <Paper
          elevation={6}
          style={{
            padding: theme.spacing(3),
            margin: theme.spacing(1),
            width: getMaxWidth(),
            maxWidth: "100%",
            backgroundColor: theme.palette.background.default, // Use the default background color
          }}
        >
          <Typography component="h1" variant="h5" textAlign="center">
            {isSignUp ? "Create your account" : "Welcome"}
          </Typography>
          {/* <TextField */}
          {/*   variant="outlined" */}
          {/*   margin="normal" */}
          {/*   required */}
          {/*   fullWidth */}
          {/*   id="email" */}
          {/*   label="Email address" */}
          {/*   name="email" */}
          {/*   autoComplete="email" */}
          {/*   autoFocus */}
          {/* /> */}
          {/* {isSignUp && ( */}
          {/*   <TextField */}
          {/*     variant="outlined" */}
          {/*     margin="normal" */}
          {/*     required */}
          {/*     fullWidth */}
          {/*     id="password" */}
          {/*     label="Password" */}
          {/*     name="password" */}
          {/*     type="password" */}
          {/*     autoComplete="current-password" */}
          {/*   /> */}
          {/* )} */}
          {/* <Button */}
          {/*   type="submit" */}
          {/*   fullWidth */}
          {/*   variant="contained" */}
          {/*   sx={{ mt: 3, mb: 2 }} */}
          {/* > */}
          {/*   Continue */}
          {/* </Button> */}
          {/* <Grid container> */}
          {/*   <Grid item xs> */}
          {/*     {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "} */}
          {/*     <Link component={RouterLink} to={isSignUp ? "/login" : "/signup"}> */}
          {/*       {isSignUp ? "Log in" : "Sign up"} */}
          {/*     </Link> */}
          {/*   </Grid> */}
          {/* </Grid> */}
          <Divider sx={{ my: 3 }} />
          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            sx={{ mb: 2 }}
            onClick={loginWithGoogle}
          >
            Continue with Google
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<MicrosoftIcon />}
            sx={{ mb: 2 }}
            onClick={loginWithMicrosoft}
          >
            Continue with Microsoft
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<AppleIcon />}
            sx={{ mb: 2 }}
            onClick={loginWithApple}
          >
            Continue with Apple
          </Button>
        </Paper>
      </Grid>
    </Container>
  );
};

export default AuthForm;
