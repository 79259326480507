import { createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// A function that returns the theme object based on the system preference
export function useCustomTheme() {
  // Use useMediaQuery to check for the system's preferred color scheme
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Create a theme instance based on the preference
  const theme = createTheme({
    palette: {
      // Set mode based on the prefersDarkMode value
      mode: prefersDarkMode ? 'dark' : 'light',
      // Define other theme properties here. You can customize the colors based on the theme mode if needed.
      background: {
        default: prefersDarkMode ? '#0F1924' :
        '#FFFFFF', // Example: Dark default background or light
        paper: prefersDarkMode ? '#0F1924' :
        '#FFFFFF', // Adjust this as needed
      },
      // Ensure text colors provide sufficient contrast
      // text: {
      //   // You can adjust these colors for better readability
      //   primary: prefersDarkMode ? '#FFFFFF' : '#000000', // White text for dark mode, black for light
      //   secondary: prefersDarkMode ? '#B2B2B2' : '#4F4F4F', // Lighter text for dark mode, darker for light
      // },
      // Uncomment and adjust the primary color based on your preference or leave it commented to use MUI defaults
      // primary: {
      //   main: '#1976d2',
      //   light: '#42a5f5',
      //   dark: '#1565c0',
      //   contrastText: '#fff',
      // },
    },
    // Add other customizations like typography, breakpoints here
  });

  return theme;
}
