import React from "react";
import { RedocStandalone } from "redoc";
import { useCustomTheme } from "../theme";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/w";

const ApiDocsPage = () => {
  const theme = useCustomTheme();
  const prefersDarkMode = theme.palette.mode === "dark";

  const redocOptions = {
    theme: {
      colors: {
        tonalOffset: 0.2,
        background: prefersDarkMode ? "#0F1924" : "#FFFFFF",
        primary: {
          main: prefersDarkMode ? "#1976d2" : "#42a5f5",
          // light: ({ colors }) =>
          //   lighten(colors.tonalOffset, colors.primary.main),
          // dark: ({ colors }) => darken(colors.tonalOffset, colors.primary.main),
          // contrastText: ({ colors }) => readableColor(colors.primary.main),
        },
        // border: {
        //   dark: prefersDarkMode ? "#ffffff" : "#000000",
        //   light: prefersDarkMode ? "#B2B2B2" : "#4F4F4F",
        // },
        http: {
          get: prefersDarkMode ? "#6bbd5b" : "#047857", // Adjust based on your preference
          post: prefersDarkMode ? "#49cc90" : "#059669", // Adjust based on your preference
          // Add other HTTP methods as needed
        },
        text: {
          primary: prefersDarkMode ? "#A9A9A9" : "#4D4D4D",
          secondary: prefersDarkMode ? "#A9A9A9" : "#4D4D4D",
        },

        // Add or adjust other colors to better match your theme
      },

      components: {
        buttons: {
          backgroundColor: prefersDarkMode ? "#1976d2" : "#1976d2",
        },
      },

      // codeblock: {
      //   backgroundColor: prefersDarkMode ? "#0F1924" : "#0F1924",
      // },

      rightPanel: {
        backgroundColor: prefersDarkMode ? "#0F1924" : "#0F1924",
        textColor: prefersDarkMode ? "#FFFFFF" : "#FFFFFF",
      },
      sidebar: {
        backgroundColor: prefersDarkMode ? "#0F1924" : "#FFFFFF",
        textColor: prefersDarkMode ? "#FFFFFF" : "#000000",
      },

      typography: {
        fontSize: theme.typography.fontSize || "16px", // Example, adjust as needed
        links: {
          color: prefersDarkMode ? "#1976d2" : "#1976d2",
        },
        heading5: {
          color: prefersDarkMode ? "#A9A9A9" : "#4D4D4D",
        },

        // Define other typography properties based on your theme
      },
      // Define other theme options as needed
    },
  };
  return (
    <RedocStandalone
      specUrl={`${API_BASE_URL}/apidoc`} // Adjust the path to your OpenAPI spec
      options={redocOptions}
    />
  );
};

export default ApiDocsPage;
