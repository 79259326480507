import React from "react";
import { Box, Container, Typography, Link as MuiLink } from "@mui/material";

const Footer = () => {
  return (
    <Box component="footer" py={6} bgcolor="background.paper">
      <Container maxWidth="lg">
        <Typography variant="body2" color="textSecondary" align="center">
          © {new Date().getFullYear()} Peptide Capital Inc. All rights
          reserved.
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" mt={2}>
          Contact us:{" "}
          <MuiLink href="mailto:us@llm-x.ai" color="inherit">
            us@llm-x.ai
          </MuiLink>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
